import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import './RedepositPage.css'; // Подключаем стили RedepositPage
import { ReactComponent as Icon } from '../WithdrawPage/icon_t/icons8-tether.svg';
import { SegmentedControl } from "@telegram-apps/telegram-ui"; // Подключаем SegmentedControl
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import { useUserInfo } from "../js/userInfo"; // Подключаем пользовательскую информацию

import { BASE_API_URL } from '../js/apiConfig';


export const RedepositPage = () => {
    const location = useLocation(); // Хук для доступа к состоянию маршрута
    const navigate = useNavigate();
    const { userId } = useUserInfo(); // Получаем id пользователя
    const balance = location.state?.balance ?? 0; // Получаем баланс из переданного состояния

    // Получаем данные из переданного состояния
    const { stakingId, amount_withdraw } = location.state || {};

    const [selectedIndex, setSelectedIndex] = useState(0); // Хук для хранения выбранного индекса
    const [isSubmitting, setIsSubmitting] = useState(false); // Состояние для кнопки отправки

    // Логика выбора редепозита
    const handleClick = ({ index }: { index: number }) => {
        setSelectedIndex(index);
    };

    // Функция для получения длительности депозита в человекочитаемом формате
    const getDurationTextByIndex = (index: number) => {
        switch (index) {
            case 0:
                return '80 дней';
            case 1:
                return '120 дней';
            case 2:
                return '240 дней';
            case 3:
                return '360 дней';
            default:
                return '80 дней';
        }
    };

    // Функция для получения длительности депозита в формате для сервера
    const getDurationForServerByIndex = (index: number) => {
        switch (index) {
            case 0:
                return 'DAYS_80';
            case 1:
                return 'DAYS_120';
            case 2:
                return 'DAYS_240';
            case 3:
                return 'DAYS_360';
            default:
                return 'DAYS_80';
        }
    };

    // Функция для получения процентной прибыли по индексу
    const getPercentageByIndex = (index: number) => {
        switch (index) {
            case 0:
                return 12;
            case 1:
                return 18;
            case 2:
                return 36;
            case 3:
                return 54;
            default:
                return 12;
        }
    };

    // Функция для отправки POST запроса редепозита
    const handleConfirm = async () => {
        const selectedDuration = getDurationForServerByIndex(selectedIndex); // Для отправки на сервер

        const payload = {
            user_id: userId, // Идентификатор пользователя
            staking_id: stakingId, // Идентификатор ставки
            staking_period: selectedDuration, // Формат для сервера (например, DAYS_80)
        };

        try {
            setIsSubmitting(true); // Блокируем кнопку во время отправки
            const response = await fetch(`${BASE_API_URL}/v1/staking/redeposit`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Преобразуем данные в JSON-формат
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            // После успешного запроса возвращаемся на главную страницу
            navigate('/');
        } catch (error) {
            console.error('Ошибка при редепозите:', error);
        } finally {
            setIsSubmitting(false); // Разблокируем кнопку
        }
    };

    const handleCancel = () => {
        navigate('/'); // Переход на главную страницу при отмене
    };

    return (
        <AppRoot>
            <div className="redeposit-container">
                <h2 className="title_r">Подтверждение редепозита</h2>
                <div className="amount-section">
                    <span className="amount_r">{amount_withdraw?.toFixed(2) ?? '0.00'} USDT</span> {/* Используем amount_withdraw */}
                    <div className="usdt-icon">
                        <Icon />
                    </div>
                </div>

                {/* Добавляем блок с выбором длительности и процентной ставки */}
                <div className="segmented-control-container">
                    <SegmentedControl style={{ width: '100%' }}>
                        <SegmentedControlItem
                            onClick={() => handleClick({ index: 0 })}
                            selected={selectedIndex === 0}
                            className={selectedIndex === 0 ? 'selected-item' : ''}
                        >
                            <span className="percentage">12%</span><br />
                            <span className="duration">80 дней</span>
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => handleClick({ index: 1 })}
                            selected={selectedIndex === 1}
                            className={selectedIndex === 1 ? 'selected-item' : ''}
                        >
                            <span className="percentage">18%</span><br />
                            <span className="duration">120 дней</span>
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => handleClick({ index: 2 })}
                            selected={selectedIndex === 2}
                            className={selectedIndex === 2 ? 'selected-item' : ''}
                        >
                            <span className="percentage">36%</span><br />
                            <span className="duration">240 дней</span>
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => handleClick({ index: 3 })}
                            selected={selectedIndex === 3}
                            className={selectedIndex === 3 ? 'selected-item' : ''}
                        >
                            <span className="percentage">54%</span><br />
                            <span className="duration">360 дней</span>
                        </SegmentedControlItem>
                    </SegmentedControl>
                </div>

                <p className="notice_r">
                    Выберите параметры редепозита. Вы можете воспользоваться функцией "Досрочный вывод" в любое время, комиссия составляет 10% от суммы депозита.
                </p>
                <div className="details_r">
                    <div className="detail-item_r">
                        <span>Сумма депозита</span>
                        <span>{amount_withdraw?.toFixed(2) ?? '0.00'} USDT</span> {/* Используем amount_withdraw */}
                    </div>
                    <div className="detail-item_r">
                        <span>Длительность депозита</span>
                        <span>{getDurationTextByIndex(selectedIndex)}</span> {/* Отображаем выбранную длительность */}
                    </div>
                    <div className="detail-item_r">
                        <span>Прибыль, %</span>
                        <span>{getPercentageByIndex(selectedIndex)}%</span> {/* Отображаем выбранную прибыль */}
                    </div>
                    <div className="detail-item_r">
                        <span>Сумма к выводу </span>
                        <span>{amount_withdraw ? (amount_withdraw * (1 + (getPercentageByIndex(selectedIndex) / 100))).toFixed(2) : '0.00'} USDT</span>
                    </div>
                </div>
                <div className="button-section_r">
                    <button className="cancel-button_r" onClick={handleCancel}>Отменить</button>
                    <button className="confirm-button_r" onClick={handleConfirm} disabled={isSubmitting}>
                        {isSubmitting ? 'Подтверждение...' : 'Подтвердить'}
                    </button>
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};
