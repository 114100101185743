import React from 'react';
import './loading.css';  // Импорт стилей
import tetherImage from '../Tabbar_main/icons/phlogo.svg'

export const LoadingPage = () => {
    return (
        <div className="loading-container">
            <div className="content-wrapper">
                <h1 className="title">Profit Harbor</h1>
                <img src={tetherImage} alt="Tether Coin" className="loading-image" />
                <div className="loading-indicator">
                    <div className="spinner"></div>
                    <span className="loading-text">Загрузка</span>
                </div>
            </div>
        </div>
    );
};
