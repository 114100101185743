// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TabbarM.css */
.tabbar-container {
    background: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tabbar_main/TabbarM.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,eAAe;AACnB","sourcesContent":["/* TabbarM.css */\n.tabbar-container {\n    background: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
