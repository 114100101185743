import '@telegram-apps/telegram-ui/dist/styles.css';
import './index.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { App } from './App';
import { AppruvePage } from './components/appruve_page/appruve';
import React, { useEffect } from "react";
import { DepositPage } from "./components/plusik/deposit";
import { TransactionPage } from "./components/history/history";
import { InviteFriendsPage } from "./components/referal_page/referal_page";
import { WithdrawPage } from "./components/WithdrawPage/WithdrawPage";
import { Progress } from "./components/staking_progress/staking_bat";
import { initViewport, SDKProvider } from "@telegram-apps/sdk-react";
import { TransactionInfo } from "./components/info/info_page";
import { RedepositPage } from "./components/redep_page/redep";
import {PopolnenieInfo} from "./components/info_plusik/info_plusik"
import {StakingInfo} from "./components/info_appruve/info_appruve";
import { WithdrawPage_Ref } from "./components/referal_withdraw/referal_withdraw";
import { AppRoot } from '@telegram-apps/telegram-ui'; // Import AppRoot

// Function to handle viewport expansion
const handleViewportExpansion = async () => {
    try {
        const [viewport] = initViewport(); // Initialize viewport
        const vp = await viewport;

        if (!vp.isExpanded) {
            await vp.expand(); // Expand if not expanded
            console.log('App was expanded.');
        } else {
            console.log('App is already expanded.');
        }
    } catch (error) {
        console.error('Error while expanding viewport:', error);
    }
};

// Main App component
const MainApp = () => {

    // Perform viewport expansion on app initialization
    useEffect(() => {
        handleViewportExpansion();
    }, []);

    return (
        <AppRoot appearance="dark"> {/* Force dark theme */}
            <Router>
                <Routes>
                    <Route path="" element={<App />} />
                    <Route path="/approve" element={<AppruvePage />} />
                    <Route path="/staking_info" element={<StakingInfo />} />
                    <Route path="/transaction-info" element={<PopolnenieInfo />} />

                    <Route path="/deposit" element={<DepositPage />} /> {/* Deposit page */}
                    <Route path="/history" element={<TransactionPage />} />
                    <Route path="/referal" element={<InviteFriendsPage />} />
                    <Route path="/withdraw" element={<WithdrawPage />} />
                    <Route path="/progress" element={<Progress />} />
                    <Route path="/transaction/:id" element={<TransactionInfo />} />
                    <Route path="/redeposit" element={<RedepositPage />} />
                    <Route path="/withdraw_ref" element={<WithdrawPage_Ref />} />

                </Routes>
            </Router>
        </AppRoot>
    );
};

const rootElement = document.getElementById('root');

// Ensure root element exists before rendering
if (rootElement) {
    const root = createRoot(rootElement as HTMLElement);

    // Render the app inside the SDKProvider
    root.render(
        <SDKProvider>
            <MainApp />
        </SDKProvider>
    );
} else {
    console.error("Root element not found.");
}
