import React, { useState, useEffect, ReactNode } from "react";
import { Tabbar } from "@telegram-apps/telegram-ui";
import { ReactComponent as Deposite } from "./icons/deposit.svg";
import { ReactComponent as Home } from "./icons/home.svg";
import { ReactComponent as Info } from "./icons/wallet.svg";
import { ReactComponent as TransactionPage } from "./icons/history.svg";
import { ReactComponent as Referal } from "./icons/withdraw.svg";
import { useNavigate, useLocation } from "react-router-dom";
import './TabbarM.css';

type TabsProps = {
    id: number;
    icon: ReactNode;
    path: string;
};

const tabs: TabsProps[] = [
    { id: 1, icon: <Home />, path: "/" },
    { id: 2, icon: <Info />, path: "/progress" },
    { id: 3, icon: <Deposite />, path: "/deposit" },
    { id: 4, icon: <TransactionPage />, path: "/history" },
    { id: 5, icon: <Referal />, path: "/referal" },
];

export const TabbarM = ({ balance }: { balance: number }) => {
    const [currentTab, setCurrentTab] = useState<number | null>(null); // Initialize with null
    const navigate = useNavigate();
    const location = useLocation();

    // Sync currentTab with the current route on mount and whenever the route changes
    useEffect(() => {
        const matchedTab = tabs.find(tab => tab.path === location.pathname);
        if (matchedTab) {
            setCurrentTab(matchedTab.id);
        } else {
            setCurrentTab(null); // Set to null if no matching tab
        }
    }, [location.pathname]);

    const handleTabClick = (id: number, path: string) => {
        setCurrentTab(id);
        navigate(path, { state: { balance } });  // Передаем баланс при навигации
    };

    return (
        <div className="tabbar-container">
            <Tabbar style={{ height: "85px" }}>
                {tabs.map(({ id, icon, path }) => (
                    <Tabbar.Item
                        key={id}
                        selected={id === currentTab}  // Selected only if matching currentTab
                        onClick={() => handleTabClick(id, path)}  // Передаем баланс при клике
                    >
                        {icon}
                    </Tabbar.Item>
                ))}
            </Tabbar>
        </div>
    );
};
