// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;

  background: #212121;
  font-family: 'Arial', sans-serif;

}
iframe
{
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;;EAEhB,mBAAmB;EACnB,gCAAgC;;AAElC;AACA;;EAEE,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  overflow: hidden;\n\n  background: #212121;\n  font-family: 'Arial', sans-serif;\n\n}\niframe\n{\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
