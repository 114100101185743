import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TransactionInfoPage.css';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { ReactComponent as DepositIcon } from '../Tabbar_main/icons/arrow_d.svg';
import { useUserInfo } from "../js/userInfo";
import { BASE_API_URL } from '../js/apiConfig';
import { initBackButton } from "@telegram-apps/sdk-react";

export const TransactionInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { transaction, balance } = location.state || {};
    const { username, userId } = useUserInfo();
    const [isCheckingStatus, setIsCheckingStatus] = useState(false);
    const [status, setStatus] = useState(transaction?.status || '');
    const [isLoading, setIsLoading] = useState(false);  // Состояние для загрузки

    // Функция для GET запроса на обновление депозита
    const updateAccountBalance = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/transaction/deposit-update?user_id=${userId}&transaction_id=${transaction.id}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            const data = await response.json();
            console.log('Обновление депозита:', data);
            setStatus(data.result?.status || 'Статус не найден');

        } catch (error) {
            console.error('Ошибка при обновлении баланса:', error);
        } finally {
            setIsCheckingStatus(false);
        }
    };

    // Функция для GET запроса обновления статуса транзакции
    const handleCheckStatus = async () => {
        try {
            setIsCheckingStatus(true);
            const response = await fetch(`${BASE_API_URL}/v1/transaction/update?id=${transaction.id}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            const data = await response.json();
            setStatus(data.result?.message?.status || 'Статус не найден');
        } catch (error) {
            console.error('Ошибка при проверке статуса транзакции:', error);
        } finally {
            setIsCheckingStatus(false);
        }
    };

    // Добавляем эффект для обработки кнопки "Назад"
    useEffect(() => {
        const [backButton] = initBackButton();
        backButton.show();
        backButton.on('click', () => {
            navigate(-1);
        });
    }, [navigate]);

    // Функция для показа индикатора загрузки на 1 секунду
    const showLoadingIndicator = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const handleGoBack = () => {
        navigate("/history");
    };

    if (!transaction) {
        return <p>Данные о транзакции недоступны</p>;
    }

    const displayStatus = status === 'pending'
        ? 'В обработке'
        : status === 'error' && transaction.type === 'ACCOUNT_BALANCE'
            ? 'Выполняется'
            : status === 'error'
                ? 'Ошибка'
                : status === 'success'
                    ? 'Выполнен'
                    : status;

    const transactionTitle = transaction.type === 'WITHDRAW'
        ? 'Вывод'
        : transaction.type === 'ACCOUNT_BALANCE'
            ? 'Пополнение'
            : 'Ввод';

    return (
        <AppRoot>
            <div className="transaction-info-container">
                <div className="transaction-info-header">
                    <div className="transaction-icon">
                        <DepositIcon />
                    </div>
                    <h2>{transactionTitle}</h2> {/* Dynamic title based on transaction type */}
                    <h3>{transaction.amount} USDT</h3>
                </div>

                {/* Показать индикатор загрузки или детали транзакции */}
                {isLoading ? (
                    <div className="loading-indicator">
                        <div className="spinner"></div>
                        <span className="loading-text">Загрузка</span>
                    </div>
                ) : (
                    <div className="transaction-details">
                        <ul>
                            <li><strong>Никнейм:</strong> {username || 'Не указано'}</li>
                            <li><strong>Дата:</strong> {new Date(transaction.created_at * 1000).toLocaleString()}</li>
                            <li><strong>Сумма:</strong> {transaction.amount} USDT</li>

                            {transaction.type === 'WITHDRAW' && (
                                <li><strong>Адрес:</strong> {transaction.withdraw_address || 'Не указано'}</li>
                            )}

                            <li><strong>Статус:</strong> {displayStatus}</li>
                        </ul>
                    </div>
                )}

                <div className="transaction-status-check">
                    {transaction.type === 'ACCOUNT_BALANCE' ? (
                        <div className="button-section">
                            <button
                                className="check-status-button"
                                onClick={() => {
                                    showLoadingIndicator();  // Показать индикатор загрузки
                                    updateAccountBalance();
                                }}
                                disabled={isCheckingStatus}
                            >
                                {isCheckingStatus ? 'Обновление...' : 'Обновить баланс'}
                            </button>
                        </div>
                    ) : transaction.type === 'WITHDRAW' ? (
                        <div className="button-section">
                            <button
                                className="confirm-button_a"
                                onClick={() => {
                                    showLoadingIndicator();  // Показать индикатор загрузки
                                    handleCheckStatus();
                                }}
                                disabled={isCheckingStatus}
                            >
                                {isCheckingStatus ? 'Проверка...' : 'Проверить Статус'}
                            </button>
                            <button
                                className="cancel-button_a"
                                onClick={handleGoBack}
                            >
                                Назад
                            </button>
                        </div>
                    ) : (
                        <button
                            className="check-status-button"
                            onClick={handleGoBack}
                        >
                            Назад
                        </button>
                    )}
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};