import React, { useState, useEffect } from 'react';
import { AppRoot, Input } from "@telegram-apps/telegram-ui";
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg'; // Импорт основного SVG
import { ReactComponent as CopyIcon } from '../Tabbar_main/icons/copy_icon.svg'; // Импорт SVG для кнопки копирования
import './DepositPage.css'; // Подключение CSS
import { TabbarM } from "../Tabbar_main/Tabbar"; // Подключение CSS
import { useUserInfo } from "../js/userInfo";
import { useLocation, useNavigate } from 'react-router-dom';
import { SegmentedControl } from '@telegram-apps/telegram-ui'; // Предполагаем, что SegmentedControl доступен
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import { BASE_API_URL } from '../js/apiConfig';

export const DepositPage = () => {
    const [selectedMethod, setSelectedMethod] = useState('manual');
    const [inputValue, setInputValue] = useState<string>(''); // Состояние для суммы
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true); // Состояние для кнопки "Подтвердить"
    const [errorMessage, setErrorMessage] = useState<string>(''); // State for error message
    const location = useLocation();
    const balance = location.state?.balance ?? 0;
    const navigate = useNavigate();

    const [address, setAddress] = useState<string>(''); // Wallet address
    const { userId } = useUserInfo(); // Получаем user_id
    const [copySuccess, setCopySuccess] = useState<string>('');

    // Получение адреса пользователя через API
    const fetchUserWalletAddress = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/user/wallets?tg_id=${userId}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const walletAddress = data.result?.message?.TON?.address || '';
            setAddress(walletAddress); // Set the wallet address from the API
        } catch (error) {
            console.error('Ошибка при получении адреса:', error);
        }
    };

    useEffect(() => {
        fetchUserWalletAddress();
    }, []);

    // Обработчик изменения поля ввода суммы
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);

        // Проверяем, если значение меньше 100, блокируем кнопку и показываем ошибку
        const parsedValue = parseFloat(value); // Parse input as a float
        if (!isNaN(parsedValue) && parsedValue >= 100) {
            setIsButtonDisabled(false);
            setErrorMessage(''); // Clear error message when value is valid
        } else {
            setIsButtonDisabled(true);
            setErrorMessage('Минимальная сумма для пополнения — 100 USDT'); // Show error if invalid
        }
    };
    const handleUrl = () => {
        const url = "https://t.me/wallet/start"; // Replace with the actual URL
        window.open(url, '_blank'); // Opens URL in a new tab
    };

    // Функция для копирования адреса в буфер обмена
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(address);
            setCopySuccess('Адрес скопирован!');
        } catch (error) {
            console.error('Ошибка при копировании:', error);
            setCopySuccess('Ошибка при копировании');
        }

        setTimeout(() => {
            setCopySuccess('');
        }, 2000);
    };

    // POST запрос для создания депозита
    const handleCrypto = async () => {
        // Check before submission if the value is still invalid
        const parsedValue = parseFloat(inputValue); // Parse input as a float
        if (isNaN(parsedValue) || parsedValue < 100) {
            setErrorMessage('Минимальная сумма для пополнения — 100 USDT');
            return;
        }

        try {
            const response = await fetch(`${BASE_API_URL}/v1/transaction/create-deposit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userId, // ID пользователя
                    amount: parsedValue, // Введенная сумма
                }),
            });

            navigate('/transaction-info', {
                state: {
                    transactionInfo: {
                        amount: inputValue,
                        type: 'Пополнение',
                        date: new Date().toLocaleDateString(),
                        address: address
                    },
                    balance
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Ошибка запроса: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Депозит успешно создан:', data);
        } catch (error) {
            navigate('/transaction-info', {
                state: {
                    transactionInfo: {
                        amount: inputValue,
                        type: 'Пополнение',
                        date: new Date().toLocaleDateString(),
                        address: address // Pass address on error as well
                    },
                    balance
                }
            });

            console.error('Ошибка при создании депозита:', error);
        }
    };

    return (
        <AppRoot>
            <div className="deposit-container">
                <span className="title_p">Пополнение</span>
                <p className="min-usdt">Мин. 100 USDT</p>
                <div className="amount-section_d">
                    <span className="balance-amount">
                        {balance !== undefined && balance !== null ? balance.toFixed(2) : '0.00'}
                    </span>
                    <div className="usdt-icon">
                        <Icon />
                    </div>
                    <span className="usdt-text">USDT</span>
                </div>

                {/* Переключатель */}
                <div className="payment-method">
                    <SegmentedControl>
                        <SegmentedControlItem
                            onClick={() => setSelectedMethod('manual')}
                            selected={selectedMethod === 'manual'}
                            className={`segmented-control-item ${selectedMethod === 'manual' ? 'selected' : ''}`}
                        >
                            Ручной
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => setSelectedMethod('interkassa')}
                            selected={selectedMethod === 'interkassa'}
                            className={`segmented-control-item ${selectedMethod === 'interkassa' ? 'selected' : ''}`}
                        >
                            Банковская карта
                        </SegmentedControlItem>
                    </SegmentedControl>
                </div>

                {/* Поле ввода суммы */}
                {selectedMethod === 'manual' ? (
                    <>
                        <div className="input-container">
                            <Input
                                header="Сумма"
                                style={{fontSize: "12px"}}
                                value={inputValue}
                                onChange={handleInputChange} // Обработчик изменения
                                placeholder="Введите сумму"
                            />
                        </div>

                        {/* Error message */}
                        {errorMessage && (
                            <p className="error-message_d">{errorMessage}</p>
                        )}

                        <p className="notice">
                            Внимательно проверьте адрес кошелька и обязательно укажите свой ник в комментарии платежа.
                        </p>
                        <div className="address-container">
                            <Input
                                disabled
                                header="Адрес для пополнения"
                                style={{fontSize: "12px"}}
                                placeholder={address ? address : 'Загрузка...'}

                            />

                            <button
                                className="copy-button_d"
                                onClick={copyToClipboard}
                                disabled={!address}
                            >
                                <CopyIcon width="24" height="24"/>
                            </button>
                        </div>

                        {copySuccess && <p className="copy-success">{copySuccess}</p>}

                        <div className="network-section">
                            <label>Сеть</label>
                            <span>TON</span>
                        </div>

                        {/* Кнопка подтверждения */}
                        <button
                            className="add-deposit-button"
                            onClick={handleCrypto}
                            disabled={isButtonDisabled}
                        >
                            Подтвердить
                        </button>
                        <button
                            className="buy-crypto-button"
                            onClick={handleUrl}
                            disabled={false}
                        >
                            Купить криптовалюту
                        </button>
                    </>
                ) : (
                    <div className="in-development">
                        <h2>В разработке</h2>
                    </div>
                )}
            </div>
            <TabbarM balance={balance}/>
        </AppRoot>
    );
};