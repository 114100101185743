import { useInitData } from '@telegram-apps/sdk-react'; // Импортируем хук для получения данных

// Хук для получения данных пользователя и start_param
export const useUserInfo = () => {
    const initData = useInitData(); // Получаем начальные данные из Telegram SDK
    const userId = initData?.user?.id;
    const username = initData?.user?.username;

    // Проверяем, есть ли start_param в initData или получаем его из URL параметра tgWebAppStartParam
    const startParam = new URLSearchParams(window.location.search).get('tgWebAppStartParam');

    // Предпочтительно использовать параметр из initData, если он доступен, иначе — из URL

    return { userId, username, startParam };
};
