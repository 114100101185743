import React, { useState, useEffect, useRef } from 'react';
import './InviteFriendsPage.css'; // Для стилей страницы
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg';
import { AppRoot } from "@telegram-apps/telegram-ui"; // Импортируем Input
import { TabbarM } from "../Tabbar_main/Tabbar";
import { useUserInfo } from "../js/userInfo";
import { useLocation, useNavigate } from 'react-router-dom'; // Импортируем хук для навигации


import { BASE_API_URL } from '../js/apiConfig';

export const InviteFriendsPage = () => {
    const { userId } = useUserInfo(); // Получаем userId
    const [balance1, setBalance] = useState(0); // Начальное значение баланса 0
    const [friends, setFriends] = useState<any[]>([]); // Список друзей, по умолчанию массив
    const [showBanner, setShowBanner] = useState(false); // Состояние для управления видимостью баннера
    const [copySuccess, setCopySuccess] = useState<string>(''); // Состояние для сообщения об успешном копировании
    const inviteLink = `https://t.me/profitharbor_bot/app?startapp=${userId}`; // Формируем ссылку на основе userId
    const navigate = useNavigate();
    const location = useLocation();

    const balance2 = location.state?.balance ?? 0; // Получаем баланс из переданного состояния

    // Получение баланса рефералов
    const fetchReferralBalance = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/referral/balance?user_id=${userId}`, {
                method: 'GET',
                headers: { 'accept': 'application/json' }
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            // Устанавливаем баланс, если пришло null или undefined, то ставим 0
            setBalance(data.result.message);

        } catch (error) {
            console.error('Ошибка при получении баланса:', error);
            setBalance(0); // Если произошла ошибка, устанавливаем 0
        }
    };

    // Получение списка друзей
    const fetchFriends = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/referral/all_referrals?user_id=${userId}`, {
                method: 'GET',
                headers: { 'accept': 'application/json' }
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            // Убедимся, что результат всегда массив. Если результат не массив, ставим пустой массив.
            setFriends(Array.isArray(data.result.message) ? data.result.message : []);
        } catch (error) {
            console.error('Ошибка при получении друзей:', error);
            setFriends([]); // Если произошла ошибка, оставляем список пустым
        }
    };

    // Функция для копирования содержимого из поля
    const copyToClipboard = async () => {
        try {
            if (inviteLink) {
                await navigator.clipboard.writeText(inviteLink); // Копируем ссылку
                setCopySuccess('Ссылка успешно скопирована!');
            } else {
                setCopySuccess('Нет ссылки для копирования');
            }
        } catch (err) {
            setCopySuccess('Ошибка при копировании');
            console.error('Ошибка при копировании в буфер обмена:', err);
        }
    };

    // Переход на страницу вывода реферальных средств
    const handleWithdrawClick = () => {
        if (balance1 < 100) {
            // Показываем баннер если баланс меньше 100
            setShowBanner(true);
        } else {
            navigate('/withdraw_ref', { state: { userId, balance1 } }); // Переход на withdraw_ref и передача данных
        }
    };

    useEffect(() => {
        fetchReferralBalance();
        fetchFriends();
    }, [userId]);

    return (
        <AppRoot>
            <div className="invite-page_ref">
                <h1 className="h1_r">Пригласить друзей</h1>
                <p className="p1_r">Получи 6% от первого депозита</p>

                <div className="balance-section_ref">
                    <div className="balance-amount_ref">{balance1.toFixed(2)}</div>
                    <div className="balance-usdt_ref">
                        <Icon style={{ width: "48px", height: "48px" }} />
                        <span>USDT</span>
                    </div>
                </div>

                <div className="bonus-info_ref">
                    <p>Гарантированно получи 6% от первого депозита каждого приведенного тобой пользователя.</p>
                    <p>Сумма будет доступна к выводу мгновенно</p>
                    <p>Минимальная сумма вывода — 100 USDT</p>
                </div>

                {/* Показываем список только если друзья есть */}
                {friends.length > 0 && (
                    <div className="friends-list_ref">
                        <h2 className="h2_r">Список друзей ({friends.length})</h2>
                        <div className="friend-items_ref">
                            {friends.map((friend, index) => (
                                <div key={index} className="friend-item_ref">
                                    <span className="friend-name_ref">{friend.username}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Сообщение об успешном копировании */}
                {copySuccess && (
                    <div className="copy-success">
                        {copySuccess}
                    </div>
                )}

                {/* Баннер с сообщением, если баланс меньше 100 USDT */}
                {showBanner && (
                    <div className="banner-error">
                        Вывод от 100 USDT
                    </div>
                )}

                <div className="invite-buttons_ref">
                    {/* Кнопка для перехода на страницу вывода */}
                    <button className="invite-button_ref" onClick={handleWithdrawClick}>Вывод</button>

                    <button className="copy-button_ref" onClick={copyToClipboard}>Скопировать ссылку</button>
                </div>
            </div>
            <TabbarM balance={balance2} />
        </AppRoot>
    );
};
