import React, { useState, useEffect } from 'react';
import './progress.css'; // Your styles
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserInfo } from "../js/userInfo";

import { BASE_API_URL } from '../js/apiConfig';


export const Progress = () => {
    const location = useLocation();
    const balance = location.state?.balance ?? 0; // Получаем баланс из переданного состояния
    const [stakingData, setStakingData] = useState<any[]>([]); // State to store all staking data
    const [isLoading, setIsLoading] = useState(true); // For loading state
    const { userId, username } = useUserInfo(); // Destructuring to get username and id
    const navigate = useNavigate(); // Hook for navigation

    // Function to perform GET request for staking data
    const fetchStakingData = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/staking/all?tg_id=${userId}`, {
                method: 'GET',
                headers: { 'accept': 'application/json' },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const stakingArray = Array.isArray(data.result.message)
                ? data.result.message.filter((staking: any) => staking.status !== 'withdrawn')
                : [];

            setStakingData(stakingArray); // Store staking data
        } catch (error) {
            console.error('Error fetching staking data:', error);
        } finally {
            setIsLoading(false); // Set loading to false
        }
    };

    // Fetch balance and staking data when component mounts
    useEffect(() => {
        // Эмулируем задержку для демонстрации работы
        fetchStakingData();
    }, [userId]);

    // Function to calculate remaining days
    const calculateRemainingDays = (end_time: number, created_at: number) => {
        const totalTime = end_time - created_at; // Total staking time
        const remainingTime = end_time - Date.now() / 1000; // Remaining time (seconds)

        const totalDays = Math.floor(totalTime / (60 * 60 * 24)); // Total days
        const remainingDays = Math.floor(remainingTime / (60 * 60 * 24)); // Remaining days

        return { totalDays, remainingDays };
    };

    // Function to get percentage based on staking_period
    const getPercentageByPeriod = (stakingPeriod: string) => {
        switch (stakingPeriod) {
            case 'DAYS_80':
                return 12;
            case 'DAYS_120':
                return 18;
            case 'DAYS_240':
                return 36;
            case 'DAYS_360':
                return 54;
            default:
                return 0;
        }
    };

    // Navigate to Withdraw or Redeposit page based on the button clicked
    const handleWithdraw = (stakingId: string, amount_withdraw: number, isEarly: boolean = false, isRedeposit: boolean = false) => {
        const finalAmount = isEarly ? amount_withdraw * 0.9 : amount_withdraw;

        if (isRedeposit) {
            navigate('/redeposit', { state: { stakingId, amount_withdraw: finalAmount, stakingData, balance } });
        } else {
            navigate('/withdraw', { state: { stakingId, amount_withdraw: finalAmount, balance, early_withdrawal: isEarly } });
        }
    };

    const handleAddDeposit = () => {
        navigate('/', { state: { balance } });
    };

    return (
        <AppRoot>
            <div className="deposit-page">
                {/* Заголовок */}
                <div className="header12">{username}</div>

                {/* Баланс */}
                <div className="balance12">
                    <span className="balance-amount">
                        {balance !== undefined && balance !== null ? balance.toFixed(2) : '0.00'}
                    </span>
                    <div className="balance-currency">
                        <Icon style={{ width: "48px", height: "48px" }} />
                        <span>USDT</span>
                    </div>
                </div>

                {/* Проверка загрузки */}
                {isLoading ? (
                    <p>Загрузка данных...</p>
                ) : (
                    <>
                        {/* Если есть данные стейкинга */}
                        {stakingData.length > 0 ? (
                            <div className="staking-list-container">
                                {stakingData.map((staking, index) => {
                                    const { totalDays, remainingDays } = calculateRemainingDays(staking.end_time, staking.created_at);
                                    const depositAmount_w = staking.amount_withdraw;
                                    const deposit = staking.staking_amount;

                                    const endDate = new Date(staking.end_time * 1000).toLocaleDateString();
                                    const time = new Date(staking.end_time * 1000).toLocaleTimeString();

                                    const percentage = getPercentageByPeriod(staking.staking_period);

                                    return (
                                        <div key={index} className="deposit-details">
                                            <div className="deposit-info">
                                                <span>{totalDays} дней | <span style={{ color: '#00ffae' }}>+{percentage}%</span></span>
                                                <span>{depositAmount_w.toFixed(2)} USDT</span>
                                            </div>

                                            <div className="progress-bar">
                                                <div className="progress" style={{ width: `${(totalDays - remainingDays) / totalDays * 100}%` }}></div>
                                            </div>

                                            <div className="deposit-status">
                                                <span>Осталось {remainingDays >= 0 ? `${remainingDays} дней` : '0 дней'}</span>
                                                <span>{time} {endDate}</span>
                                            </div>

                                            {remainingDays > 0 ? (
                                                <button className="early-withdraw" onClick={() => handleWithdraw(staking.id, deposit, true)}>
                                                    Досрочный вывод
                                                </button>
                                            ) : (
                                                <div className="expired-actions">
                                                    <button className="redeposit-button" onClick={() => handleWithdraw(staking.id, depositAmount_w, false, true)}>
                                                        Редепозит
                                                    </button>
                                                    <button className="withdraw-button" onClick={() => handleWithdraw(staking.id, depositAmount_w)}>
                                                        Вывод
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p className="empty-state">Нет данных для отображения</p>
                        )}
                    </>
                )}

                <div className="add-deposit-section">
                    <button className="add-deposit-button" onClick={handleAddDeposit}>
                        Добавить Депозит
                    </button>
                </div>
            </div>

            {/* Передаем баланс в Tabbar */}
            <TabbarM balance={balance} />
        </AppRoot>
    );
};
