import React, { useState, useEffect } from 'react';
import './WithdrawPage.css'; // Стили
import { AppRoot, Input, List, Select } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { useLocation, useNavigate } from 'react-router-dom'; // Импортируем хук для навигации
import { useUserInfo } from "../js/userInfo";

import { BASE_API_URL } from '../js/apiConfig';
import {initBackButton} from "@telegram-apps/sdk-react";


export const WithdrawPage = () => {
    const { userId } = useUserInfo();
    const [walletAddress, setWalletAddress] = useState(""); // Адрес кошелька
    const [networkList, setNetworkList] = useState<string[]>([]); // Список сетей для дроп-меню
    const [selectedNetwork, setSelectedNetwork] = useState(""); // Выбранная сеть
    const [isSubmitting, setIsSubmitting] = useState(false); // Для контроля состояния отправки
    const [comment, setComment] = useState(""); // Комментарий к выводу
    const location = useLocation(); // Хук для доступа к переданным данным
    const navigate = useNavigate(); // Хук для навигации после завершения запроса
    const { stakingId, amount_withdraw, balance, early_withdrawal } = location.state || {}; // Получаем early_withdrawal из переданных данных

    // Обработчик изменения адреса кошелька
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWalletAddress(e.target.value);
    };

    // Обработчик изменения комментария
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    // Обработчик изменения выбранной сети
    const handleNetworkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedNetwork(e.target.value);
    };

    useEffect(() => {
        const [backButton] = initBackButton();  // Init the back button from Telegram SDK


        backButton.show()

        backButton.on('click', () => {
            navigate(-1);  // Navigate back on back button click
        });




    }, [navigate]);
    // Обработчик отправки формы вывода средств
    const handleWithdraw = async () => {
        if (!walletAddress || !selectedNetwork) {
            return;
        }

        const payload = {
            user_id: userId,
            staking_id: stakingId,
            network: selectedNetwork,
            address: walletAddress,
            early_withdrawal: !!early_withdrawal, // Передаем флаг досрочного вывода
            comment: selectedNetwork === 'TON' ? comment || "Вывод средств" : '', // Только для сети TON
        };

        try {
            setIsSubmitting(true);
            const response = await fetch(`${BASE_API_URL}/v1/staking/withdraw`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            navigate('/');
        } catch (error) {
            console.error('Ошибка при выводе средств:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchNetworks();
    }, []);

    // Функция для получения списка сетей
    const fetchNetworks = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/settings/networks`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            const data = await response.json();
            setNetworkList(data.result.message);
        } catch (error) {
            console.error('Ошибка при получении сетей:', error);
        }
    };

    return (
        <AppRoot>
            <div className="withdraw-page">
                <h1>Вывод</h1>

                <div className="input-group">
                    <label className="withdraw-label" htmlFor="walletAddress">Адрес кошелька</label>
                    <Input
                        id="walletAddress"
                        value={walletAddress}
                        onChange={handleInputChange}
                        placeholder="Введите адрес кошелька"
                    />
                </div>

                <div className="input-group">
                    <label className="withdraw-label" htmlFor="amount">Сумма вывода</label>
                    <Input
                        id="amount"
                        type="number"
                        value={amount_withdraw?.toFixed(2) || '0.00'}
                        readOnly
                    />
                </div>

                {/* Поле комментария отображается только для сети TON */}
                {selectedNetwork === 'TON' && (
                    <>
                        <div className="input-group">
                            <label className="withdraw-label" htmlFor="comment">Мнемо (Комментарий)</label>
                            <Input
                                id="comment"
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder="Мнемо для сети TON "
                            />
                        </div>
                        {/* Предупреждающее сообщение */}
                        <div className="warning-text">
                            При внесении депозита в TON необходимо указать верный адрес депозита и тег/мемо.
                            В противном случае средства будут потеряны без возможности возврата.
                        </div>
                    </>
                )}

                <div className="input-group">
                    <label className="withdraw-label" htmlFor="network">Выберите сеть</label>
                    <List style={{ width: '100%', background: 'var(--tgui--secondary_bg_color)' }}>
                        <Select
                            id="network"
                            value={selectedNetwork}
                            onChange={handleNetworkChange}
                        >
                            <option>Выберите сеть</option>
                            {networkList.map((network, index) => (
                                <option key={index} value={network}>
                                    {network}
                                </option>
                            ))}
                        </Select>
                    </List>
                </div>

                <button
                    className="confirm-button_w"
                    disabled={!walletAddress || amount_withdraw <= 0 || !selectedNetwork || isSubmitting}
                    onClick={handleWithdraw}
                >
                    {isSubmitting ? 'Подтверждение...' : 'Подтвердить'}
                </button>
            </div>

            <TabbarM balance={balance} />
        </AppRoot>
    );
};
