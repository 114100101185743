import React, { useEffect, useState } from 'react';
import { initBackButton, SDKProvider } from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { CustomSegmentedControl } from "./components/plan/plans";
import { BASE_API_URL } from "./components/js/apiConfig";
import { LoadingPage } from "./components/download/loading";
import { useUserInfo } from "./components/js/userInfo";
import { initViewport } from '@telegram-apps/sdk-react';
import { useNavigate } from "react-router-dom";
import MobileDetect from 'mobile-detect';  // Импортируем библиотеку


export const App = () => {
    const [showContent, setShowContent] = useState(() => {
        return !!localStorage.getItem('firstVisit');
    });

    const { username, userId, startParam } = useUserInfo();
    const referralId = startParam ? parseInt(startParam, 10) || 0 : 0;
    const [backButton] = initBackButton();  // Инициализация кнопки назад из Telegram SDK
    const [isMobile, setIsMobile] = useState(true); // Состояние для проверки мобильного устройства

    // Функция для создания пользователя
    const createUser = async () => {
        const payload = {
            tg_id: userId,
            username: username || 'anonymous',
            referral_id: referralId
        };

        try {
            const response = await fetch(`${BASE_API_URL}/v1/user/create`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Ошибка ответа от сервера: ${response.status} - ${errorText}`);
                throw new Error(`Ошибка создания пользователя. HTTP статус: ${response.status}`);
            }

            console.log('Пользователь успешно создан');
        } catch (error) {
            console.error('Ошибка при создании пользователя:', error);
        }
    };

    // Функция для инициализации viewport и его расширения
    const expandApp = async () => {
        try {
            const [viewport] = initViewport(); // Инициализируем viewport
            const vp = await viewport; // Ждем получения данных

            if (!vp.isExpanded) {
                vp.expand(); // Расширяем мини-приложение, если оно не расширено
                console.log('Мини-приложение было расширено.');
            }
        } catch (error) {
            console.error('Ошибка при работе с viewport:', error);
        }
    };

    // Функция для проверки устройства с помощью MobileDetect
    const checkDevice = () => {
        const md = new MobileDetect(window.navigator.userAgent); // Инициализируем MobileDetect

        if (md.mobile()) {
            setIsMobile(true);  // Если мобильное устройство, устанавливаем флаг
        } else {
            setIsMobile(true); // false
        }
    };

    useEffect(() => {
        checkDevice(); // Проверяем устройство при загрузке приложения

        const initApp = async () => {
            await expandApp(); // Расширяем приложение
            backButton.hide(); // Скрываем кнопку назад, если это необходимо

            if (!localStorage.getItem('firstVisit')) {
                createUser();
                localStorage.setItem('firstVisit', 'true');

                // Таймер для показа контента после загрузки
                const timer = setTimeout(() => {
                    setShowContent(true);
                }, 6500);

                return () => clearTimeout(timer);
            } else {
                setShowContent(true);
            }
        };

        initApp(); // Запуск инициализации приложения
    }, []);

    if (!isMobile) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' ,color:'white' }}>
                <h1>Это приложение доступно только на мобильных устройствах!</h1>
                <p>Пожалуйста, зайдите с вашего телефона или планшета.</p>
            </div>
        );
    }

    return (
        <SDKProvider>
            <AppRoot platform="base" appearance="dark">
                {showContent ? (
                    <CustomSegmentedControl />
                ) : (
                    <LoadingPage />
                )}
            </AppRoot>
        </SDKProvider>
    );
};