// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Центрирование содержимого */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a; /* Темный фон */
}

.content-wrapper {
    text-align: center;
}

/* Стили для заголовка */
.title {
    color: #2d78f4;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

/* Стили для изображения */
.loading-image {
    width: 293px;
    height: 293px;

    margin-bottom: 30px;
}

/* Обертка для индикатора загрузки */
.loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Текст загрузки */
.loading-text {
    color: #2d78f4;
    margin-top: 10px;
    font-size: 1.2rem;
}

/* Стили для спиннера */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #2d78f4;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/download/loading.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,eAAe;AAC9C;;AAEA;IACI,kBAAkB;AACtB;;AAEA,wBAAwB;AACxB;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA,0BAA0B;AAC1B;IACI,YAAY;IACZ,aAAa;;IAEb,mBAAmB;AACvB;;AAEA,oCAAoC;AACpC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA,mBAAmB;AACnB;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA,uBAAuB;AACvB;IACI,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,kCAAkC;AACtC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* Центрирование содержимого */\n.loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #1a1a1a; /* Темный фон */\n}\n\n.content-wrapper {\n    text-align: center;\n}\n\n/* Стили для заголовка */\n.title {\n    color: #2d78f4;\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n}\n\n/* Стили для изображения */\n.loading-image {\n    width: 293px;\n    height: 293px;\n\n    margin-bottom: 30px;\n}\n\n/* Обертка для индикатора загрузки */\n.loading-indicator {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n/* Текст загрузки */\n.loading-text {\n    color: #2d78f4;\n    margin-top: 10px;\n    font-size: 1.2rem;\n}\n\n/* Стили для спиннера */\n.spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    border-left-color: #2d78f4;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
