import React, { useState, useEffect } from 'react';
import { SegmentedControl } from "@telegram-apps/telegram-ui";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import './customSegmentedControl.css';
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg';
import { ReactComponent as Arrow } from "../Tabbar_main/icons/arrow.svg";
import { useUserInfo } from "../js/userInfo";
import { Input, Section } from '@telegram-apps/telegram-ui';
import { useNavigate } from "react-router-dom";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { BASE_API_URL } from '../js/apiConfig';

export const CustomSegmentedControl = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [balance, setBalance] = useState<number | null>(null);
    const [loadingBalance, setLoadingBalance] = useState(true); // Исправление здесь
    const [inputValue, setInputValue] = useState('');
    const [calculatedValue, setCalculatedValue] = useState('0.00');
    const [error, setError] = useState<string | null>(null);
    const [inputError, setInputError] = useState<string | null>(null); // State to handle input error
    const { username, userId } = useUserInfo();
    const navigate = useNavigate();
    const periods = ["DAYS_80", "DAYS_120", "DAYS_240", "DAYS_360"];
    const percentages = [12, 18, 36, 54];

    // Function to fetch user info and balance (GET request)
    const fetchUserInfoAndBalance = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/user/info?tg_id=${userId}`, {
                method: 'GET',
                headers: { 'accept': 'application/json' }
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            const data = await response.json();
            if (data?.result?.message?.balance !== undefined) {
                const userBalance = parseFloat(data.result.message.balance.toFixed(2));
                setBalance(userBalance);
            } else {
                console.error("Поле 'balance' не найдено в ответе API.");
                setBalance(0);
            }
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
            setError('Ошибка при получении данных пользователя. Попробуйте снова.');
        } finally {
            setLoadingBalance(false); // Indicate balance fetching is complete
        }
    };

    // Initialize user creation and balance fetching
    const initializeUser = async () => {
        await fetchUserInfoAndBalance(); // Fetch balance after user creation
    };

    useEffect(() => {
        initializeUser(); // Fetch balance after user is created
    }, []); // Fetch balance only when user creation is done

    // Handle deposit period selection
    const handleClick = ({ index }: { index: number }) => {
        setSelectedIndex(index);
        if (inputValue) {
            const inputAmount = parseFloat(inputValue);
            const finalValue = inputAmount + (inputAmount * percentages[index]) / 100;
            setCalculatedValue(finalValue.toFixed(2));
        }
    };

    // Handle input change with validation for minimum value (100 USDT)
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Проверка: число может содержать точку и быть отрицательным
        const isValid = /^-?\d*\.?\d*$/.test(value);
        if (isValid) {
            setInputValue(value);

            // Попробуем преобразовать в число
            const inputAmount = parseFloat(value);

            // Проверка на то, является ли введённое значение числом
            if (isNaN(inputAmount)) {
                setInputError("Введите корректное число");
                setCalculatedValue(''); // Очищаем calculatedValue при некорректном вводе
                return;
            }

            // Минимальная сумма депозита
            if (inputAmount < 100) {
                setInputError("Минимальная сумма депозита 100 USDT");
            } else {
                setInputError(null); // Убираем ошибку, если всё корректно
            }

            // Рассчитываем значение с процентом
            const finalValue = inputAmount + (inputAmount * percentages[selectedIndex]) / 100;
            setCalculatedValue(finalValue.toFixed(2)); // Округляем до двух знаков
        } else {
            setInputError("Некорректное значение");
        }
    };

    // Handle deposit button click
    // Handle deposit button click
    const handleDepositClick = () => {
        // Если баланс равен 0, перенаправляем пользователя на страницу пополнения
        if (balance === 0) {
            navigate('/deposit', { state: { balance } });
            return; // Останавливаем дальнейшую логику
        }

        // Если введенное значение меньше 100, то отменяем дальнейшие действия
        if (parseFloat(inputValue) < 100) {
            return; // Prevent deposit if input is less than 100
        }

        const periodMapping = {
            DAYS_80: "80 дней",
            DAYS_120: "120 дней",
            DAYS_240: "240 дней",
            DAYS_360: "360 дней"
        };

        const periodKey = periods[selectedIndex] as keyof typeof periodMapping;
        const stakingPeriod = periodMapping[periodKey] || "80 дней";
        const profitPercentage = percentages[selectedIndex];

        const data = {
            user_id: userId,
            staking_amount: parseFloat(inputValue),
            staking_duration: stakingPeriod,
            profit_percentage: profitPercentage,
            staking_period: periodKey,
        };

        // Навигация на страницу подтверждения депозита
        navigate('/approve', { state: { data } });
    };
    const Popolnenie = () => {
        navigate('/deposit', { state: { balance } });
    }


    return (
        <div className="container">
            <>
                <div className="top-left-text">{username || 'Загрузка...'}</div>
                <div className="header">
                    <div className="number-variable">
                        {balance !== null ? balance.toFixed(2) : '0.00'}
                    </div>
                    <div className="svg-text-container">
                        <div className="svg-icon">
                            <Icon width="48" height="48" />
                        </div>
                        <div className="text-label">USDT</div>
                    </div>
                </div>

                <div className="segmented-control-container">
                    <SegmentedControl style={{ width: '100%' }}>
                        {periods.map((period, index) => (
                            <SegmentedControlItem
                                key={index}
                                onClick={() => handleClick({ index })}
                                selected={selectedIndex === index}
                                className={selectedIndex === index ? 'selected-item' : ''}
                            >
                                <span className="percentage">{percentages[index]}%</span><br />
                                <span className="duration">{period.replace('DAYS_', '')} дней</span>
                            </SegmentedControlItem>
                        ))}
                    </SegmentedControl>
                </div>

                <Section>
                    <div style={{position: 'relative'}}>
                        <Input
                            header="Вы вносите, USDT"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                        {inputError && <div className="error-message">{inputError}</div>} {/* Display error message */}

                        {/* Линия между двумя инпутами */}
                        {/*<hr style={{border: '1px solid #2990FF', margin: '2px 20px'}}/>*/}
                        <Arrow style={{width:"36px", height:"36px"}}></Arrow>
                        <div style={{position: 'relative'}}>
                            <Input
                                disabled
                                header="Вы получите, USDT"
                                placeholder={calculatedValue}
                            />
                        </div>
                        <button
                            className={`deposit-button_c ${inputValue && parseFloat(inputValue) >= 100 ? 'active' : ''}`}
                            onClick={handleDepositClick}
                            disabled={!inputValue || parseFloat(inputValue) < 100} // Disable if input < 100
                        >
                            Создать Депозит
                        </button>

                    <button
                        className="plusik-button_c"
                        onClick={Popolnenie}

                    >
                        Пополнить
                    </button>
        </div>
                </Section>

                <TabbarM balance={balance || 0}/>
            </>
        </div>
    );
};