import React, { useState, useEffect } from "react";
import './TransactionPage.css';
import { initBackButton} from '@telegram-apps/sdk-react';

import { TabbarM } from "../Tabbar_main/Tabbar";
import { ReactComponent as Icon } from './icon_t/icons8-tether.svg';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { useUserInfo } from "../js/userInfo";
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DepositIcon } from '../Tabbar_main/icons/arrow_d.svg'; // Импорт иконки "Ввод"

import { BASE_API_URL } from '../js/apiConfig';


export const TransactionPage = () => {
    const [backButton] = initBackButton();  // Инициализация кнопки назад из Telegram SDK

    const location = useLocation(); // Hook to access location state
    const { userId } = useUserInfo();
    const [transactionsData, setTransactionsData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate(); // Для навигации при клике
    const balance = location.state?.balance ?? 0; // Получаем баланс из переданного состояния

    // Функция для получения транзакций
    const fetchTransactions = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/transaction/all?tg_id=${userId}`, {
                method: 'GET',
                headers: { 'accept': 'application/json' }
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            setTransactionsData(data.result.message); // Используем поле "message" для данных транзакций
            setIsLoading(false);
        } catch (error: any) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    // Используем useEffect для получения данных при монтировании компонента
    useEffect(() => {
        fetchTransactions();
        backButton.hide(); // Скрываем кнопку назад, если это необходимо

    }, [userId]);

    const handleAddDeposit = () => {
        navigate('/', { state: { balance } });
    };

    // Форматирование даты в виде "Сегодня", "Вчера" или в формате "дд/мм/гггг"
    const formatTransactionDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return 'Сегодня';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Вчера';
        } else {
            return date.toLocaleDateString(); // Дата в формате дд/мм/гггг
        }
    };

    // Группируем транзакции по дате
    const groupTransactionsByDate = (transactions: any[]) => {
        return transactions.reduce((groups, transaction) => {
            const date = formatTransactionDate(transaction.created_at); // Форматируем дату
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
        }, {} as Record<string, any[]>);
    };

    // Сортируем транзакции и группируем их по дате
    const groupedTransactions = groupTransactionsByDate(
        Array.isArray(transactionsData) ? transactionsData.sort((a, b) => b.created_at - a.created_at) : [] // Безопасная проверка
    );



    // Обработчик для клика на транзакцию
    const handleTransactionClick = (transaction: any) => {
        navigate(`/transaction/${transaction.id}`, { state: { transaction, balance: balance } }); // Передаем данные транзакции и баланс
    };


    return (
        <AppRoot>
            <div className="transaction-page_h">
                <div className="balance-section">
                    <span className="title_p">История</span>
                    <p className={"transact"}>Транзакции и операции</p>
                    <div className="balance-amount">
                        <span className="balance">
                        {balance !== undefined && balance !== null ? balance.toFixed(2) : '0.00'} {/* Проверяем значение перед вызовом toFixed */}
                        </span> {/* Отображаем баланс */}
                        <div className="usdt-icon_h">
                            <Icon />
                        </div>
                        <span className="usdt-text_h">USDT</span>
                    </div>
                </div>

                {isLoading ? (
                    <p>Загрузка транзакций...</p>
                ) : error ? (
                    <p className="error">Ошибка: {error}</p>
                ) : transactionsData.length === 0 ? ( // Проверяем, если транзакций нет
                    <p className="empty-state">Нет транзакций для отображения</p>
                ) : (
                    <div className="transaction-list_h">
                        {Object.keys(groupedTransactions).map((date) => (
                            <div key={date}>
                                <div className="transaction-date">{date}</div>
                                {groupedTransactions[date].map((transaction: any) => (
                                    <div
                                        key={transaction.id}
                                        className={`transaction-item_h ${
                                            transaction.status === 'success'
                                                ? 'success'
                                                : transaction.status === 'pending'
                                                    ? 'pending'
                                                    : 'error'
                                        }`}
                                        onClick={() => handleTransactionClick(transaction)} // Добавляем обработчик клика
                                    >
                                        <div className="transaction-info_h">
                                            <DepositIcon className="transaction-icon_h" />
                                            <div className="transaction-text_h">
                                                <div className="transaction-type_h">
                                                    {transaction.type === 'WITHDRAW'
                                                        ? 'Вывод'
                                                        : transaction.type === 'ACCOUNT_BALANCE'
                                                            ? 'Пополнение'
                                                            : 'Ввод'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="transaction-amount">
                                        <span>{transaction.status === 'success' ? `+${transaction.amount}` : `+${transaction.amount}`} USDT</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}

                <div className="add-deposit-section_h">
                    <button className="deposit-button_h" onClick={handleAddDeposit}>
                        Добавить Депозит
                    </button>
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};
