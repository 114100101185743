import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StakingInfoPage.css'; // Импортируем стили
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { useUserInfo } from "../js/userInfo";
import { initBackButton } from "@telegram-apps/sdk-react";
import { ReactComponent as Icon } from '../Tabbar_main/icons/icons8-галочка.svg';

export const StakingInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { stakingInfo, balance } = location.state || {}; // Получаем данные стейкинга и баланс из переданного состояния
    const { username } = useUserInfo();

    const [isCheckingStatus, setIsCheckingStatus] = useState(false);
    const [status, setStatus] = useState(stakingInfo?.status || '');

    useEffect(() => {
        const [backButton] = initBackButton();
        backButton.show();

        backButton.on('click', () => {
            navigate(-1);
        });
    }, [navigate]);

    const handleGoBack = () => {
        navigate(-1); // Возврат на предыдущую страницу
    };

    const handleNavigateToProgress = () => {
        navigate('/progress', { state: { balance } }); // Переход на страницу progress с передачей balance
    };

    if (!stakingInfo) {
        return <p>Данные о стейкинге недоступны</p>;
    }

    const displayStatus = status === 'pending'
        ? 'В обработке'
        : status === 'error'
            ? 'Ошибка'
            : 'Завершен';

    return (
        <AppRoot>
            <div className="staking-info-container">
                <div className="staking-info-header">
                    <Icon style={{ width: "64px", height: "64px" }} />
                    <h2>Депозит Внесён</h2>
                    <h3>{stakingInfo.staking_amount} USDT</h3>
                    <p>Ваш депозит успешно активирован</p>
                </div>

                <div className="staking-details">
                    <ul>
                        <li><strong>Никнейм:</strong> {username || 'Не указано'}</li>
                        <li><strong>Сумма Депозита:</strong> {stakingInfo.staking_amount}</li>
                        <li><strong>Период стейкинга:</strong> {stakingInfo.staking_duration}</li>
                        <li><strong>Процент прибыли:</strong> {stakingInfo.profit_percentage}%</li>
                        <li><strong>Статус:</strong> {displayStatus}</li>
                    </ul>
                </div>

                <div className="button-section_ina">
                    <button
                        className="check-status-button"
                        onClick={handleNavigateToProgress} // Вызов функции навигации
                    >
                        Депозиты
                    </button>
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};