import React, { useState, useEffect } from 'react';
import '../WithdrawPage/WithdrawPage.css'; // Стили
import { AppRoot, Input, List, Select } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { useNavigate } from 'react-router-dom'; // Хук для навигации
import { useUserInfo } from "../js/userInfo";
import { useLocation } from 'react-router-dom';

import { BASE_API_URL } from '../js/apiConfig';
import {initBackButton} from "@telegram-apps/sdk-react";


export const WithdrawPage_Ref = () => {
    const { userId } = useUserInfo(); // Получаем userId
    const location = useLocation();

    const [walletAddress, setWalletAddress] = useState(""); // Адрес кошелька
    const [networkList, setNetworkList] = useState<string[]>([]); // Список сетей для дроп-меню
    const [selectedNetwork, setSelectedNetwork] = useState(""); // Выбранная сеть
    const [isSubmitting, setIsSubmitting] = useState(false); // Для контроля состояния отправки
    const [comment, setComment] = useState(""); // Комментарий к выводу
    const navigate = useNavigate(); // Хук для навигации после завершения запроса
    const balance = location.state?.balance ?? 0; // Получаем баланс из переданного состояния

    // Обновление адреса кошелька
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWalletAddress(e.target.value);
    };

    // Обновление комментария
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    // Обработчик для обновления выбранной сети
    const handleNetworkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedNetwork(e.target.value);
    };
    useEffect(() => {
        const [backButton] = initBackButton();  // Init the back button from Telegram SDK


        backButton.show()

        backButton.on('click', () => {
            navigate(-1);  // Navigate back on back button click
        });




    }, [navigate]);

    // Обработчик для отправки POST запроса
    const handleWithdraw = async () => {
        if (!walletAddress || !selectedNetwork) {
            return; // Если не выбрана сеть или адрес кошелька пуст, не продолжаем
        }

        const payload = {
            user_id: userId, // Добавляем user_id
            network: selectedNetwork, // Добавляем выбранную сеть
            address: walletAddress, // Адрес кошелька
            comment: selectedNetwork === 'TON' ? comment || "Вывод средств" : '', // Комментарий обязателен только для сети TON
        };

        try {
            setIsSubmitting(true); // Отключаем кнопку на время выполнения запроса
            const response = await fetch(`${BASE_API_URL}/v1/referral/withdraw`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Преобразуем объект в JSON строку
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            // После успешного запроса перенаправляем на страницу с историей или успехом
            navigate('/referal'); // Это можно заменить на любую нужную страницу
        } catch (error) {
            console.error('Ошибка при выводе средств:', error);
        } finally {
            setIsSubmitting(false); // Возвращаем возможность нажимать на кнопку после завершения запроса
        }
    };

    // Функция для получения списка сетей из API
    const fetchNetworks = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/v1/settings/networks`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }

            const data = await response.json();
            setNetworkList(data.result.message); // Сохраняем список сетей
        } catch (error) {
            console.error('Ошибка при получении сетей:', error);
        }
    };

    // Получаем список сетей при монтировании компонента
    useEffect(() => {
        fetchNetworks();
    }, []);

    return (
        <AppRoot>
            <div className="withdraw-page">
                <h1>Вывод средств</h1>

                <div className="input-group">
                    <label className="withdraw-label" htmlFor="walletAddress">Адрес кошелька</label>
                    <Input
                        id="walletAddress"
                        value={walletAddress}
                        onChange={handleInputChange}
                        placeholder="Введите адрес кошелька"
                    />
                </div>

                {/* Комментарий отображается только для сети TON */}
                {selectedNetwork === 'TON' && (
                    <>
                        <div className="input-group">
                            <label className="withdraw-label" htmlFor="comment">Мнемо (Комментарий)</label>
                            <Input
                                id="comment"
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder="Мнемо для сети TON "
                            />
                        </div>

                        {/* Предупреждение для сети TON */}
                        <div className="warning-text">
                            При внесении депозита в TON необходимо указать верный адрес депозита и тег/мемо.
                            В противном случае средства будут потеряны без возможности возврата.
                        </div>
                    </>
                )}

                <div className="input-group">
                    <label className="withdraw-label" htmlFor="network">Выберите сеть</label>
                    <List style={{ width: '100%', background: 'var(--tgui--secondary_bg_color)' }}>
                        <Select
                            id="network"
                            value={selectedNetwork}
                            onChange={handleNetworkChange}
                        >
                            <option>Выберите сеть</option>
                            {networkList.map((network, index) => (
                                <option key={index} value={network}>
                                    {network} {/* Отображаем имя сети */}
                                </option>
                            ))}
                        </Select>
                    </List>
                </div>

                <button
                    className="confirm-button_w"
                    disabled={!walletAddress || !selectedNetwork || isSubmitting}
                    onClick={handleWithdraw}
                >
                    {isSubmitting ? 'Подтверждение...' : 'Подтвердить'}
                </button>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};
