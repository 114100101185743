import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TabbarM } from "../Tabbar_main/Tabbar";
import { useUserInfo } from "../js/userInfo";
import { initBackButton } from "@telegram-apps/sdk-react";
import { ReactComponent as Icon } from '../Tabbar_main/icons/clock.svg';

export const PopolnenieInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { transactionInfo, balance } = location.state || {}; // Получаем данные транзакции и баланс из переданного состояния
    const { username } = useUserInfo();

    const [isCheckingStatus, setIsCheckingStatus] = useState(false);
    const [status, setStatus] = useState(transactionInfo?.status || '');

    useEffect(() => {
        const [backButton] = initBackButton();
        backButton.show();

        backButton.on('click', () => {
            navigate(-1);
        });
    }, [navigate]);

    const handleGoBack = () => {
        navigate(-1); // Возврат на предыдущую страницу
    };

    const handleNavigateToProgress = () => {
        navigate('/history', { state: { balance } }); // Переход на страницу progress с передачей balance
    };

    if (!transactionInfo) {
        return <p>Данные о транзакции недоступны</p>;
    }

    // Debugging: Check if transactionInfo contains the address
    console.log(transactionInfo);

    const displayStatus = status === 'pending'
        ? 'В обработке'
        : status === 'error'
            ? 'Ошибка'
            : 'Завершен';

    return (
        <AppRoot>
            <div className="transaction-info-container">
                <div className="transaction-info-header">
                    <Icon style={{ width: "64px", height: "64px" }} />
                    <h2>Запрос на пополнение отправлен</h2>
                    <span className="amount" >{transactionInfo.amount} USDT</span>
                    <p>Ожидается перевод средств</p>
                </div>

                <div className="transaction-details">
                    <ul>
                        <li><strong>Никнейм:</strong> {username || 'Не указано'}</li>
                        <li><strong>Сумма Транзакции:</strong> {transactionInfo.amount}</li>
                        <li><strong>Тип Транзакции:</strong> {transactionInfo.type}</li>
                        <li><strong>Дата Транзакции:</strong> {transactionInfo.date}</li>

                        {/* Conditionally render the address if it exists */}
                        <li><strong>Адрес:</strong> <span>{transactionInfo.address ? transactionInfo.address : 'Адрес не указан'}</span></li>

                    </ul>
                </div>

                <div className="button-section_ina">
                    <button
                        className="check-status-button"
                        onClick={handleNavigateToProgress}
                    >
                        К транзакциям
                    </button>
                </div>
            </div>
            <TabbarM balance={balance} />
        </AppRoot>
    );
};